import { NgModule } from '@angular/core';
import { OnlyNumber } from './directives/onlynumber.directive';
import { OnlyNumberNotDot } from './directives/onlynumberNotDot.directive'

@NgModule({
  imports: [],
  declarations: [OnlyNumber, OnlyNumberNotDot],
  exports: [OnlyNumber, OnlyNumberNotDot]
})
export class DirectivesModule { }
