
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumberNotDot]'
})
export class OnlyNumberNotDot {

  constructor(private el: ElementRef) { }

  @Input() OnlyNumberNotDot: boolean;

  @HostListener('keypress', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    const charCode = (e.which) ? e.which : e.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}

		return true;
  }
}